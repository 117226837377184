import React from "react";
import { createRoot } from 'react-dom/client'
import './styles.css'
import { App } from './App'

const pexel = (id) => `https://images.pexels.com/photos/${id}/pexels-photo-${id}.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260`
const images = [
    // Front
    { position: [0, 0, 1.5], rotation: [0, 0, 0], url: '/images/X/X_angle.jpg' },
    // Back
    { position: [-1.5, 3, -0.6], rotation: [0, Math.PI / 5.5, 0], url: '/images/X/X_lightbeams.jpg' },
    { position: [1.5, 3, -0.6], rotation: [0, Math.PI / -5.5, 0], url: '/images/X/X_lasers.jpg' },
    // Left
    { position: [-2.2, 0, 0.25], rotation: [0, Math.PI / 4.5, 0], url: '/images/X/X_club.jpg' },
    { position: [-2.75, 2, 1.5], rotation: [0, Math.PI / 2.5, 0], url: '/images/X/X_green.jpg' },
    { position: [-2.8, 0, 2.75], rotation: [0, Math.PI / 2.5, 0], url: '/images/X/X_pink.jpg' },
    // Right
    { position: [2.2, 0, 0.25], rotation: [0, -Math.PI / 4.5, 0], url: '/images/X/X_cubic_3x4.jpg' },
    { position: [2.75, 2, 1.5], rotation: [0, -Math.PI / 2.5, 0], url: '/images/X/X_day.jpg' },
    { position: [2.8, 0, 2.75], rotation: [0, -Math.PI / 2.5, 0], url: '/images/X/X_haze.jpg' }
  ]

createRoot(document.getElementById('root')).render(<App images={images} />)
